import axios from "axios";
import Env from "@/logics/Envs.js";

export default async function (classId, formItems) {
    try {
        let error = null;
        const copy = JSON.parse(JSON.stringify(formItems));
        copy.forEach(function (item) {
            delete item.tempOption;
            if (!item.zh || !item.en) {
                error = "Please input the titles!";
            }
        });
        if (error) {
            return error;
        }
        const toSend = new FormData();
        toSend.append("classId", classId);
        toSend.append("formJson", JSON.stringify(copy));

        const res = await axios.post(Env.apiPath + "saveClassForm", toSend);
        return res.data.error;
    } catch (err) {
        return err;
    }
}