<template>
    <div class="pt-32 flex flex-wrap" v-if="classDetails">
        <div class="md:w-1/2 w-full mb-12">
            <class-details-card :class-details="classDetails" :lang-code="'zh'" :my-theme="myTheme" />
        </div>
        <div class="md:w-1/2 w-full">
            <div class="flex flex-col items-center px-4 gap-y-3">
                <van-cell-group v-for="(item, index) in formItems" :key="index" class="shadow-md w-full" inset>
                    <van-field v-model="item.zh" label="中文标题" placeholder="请输入" />
                    <van-field v-model="item.en" label="英文标题" placeholder="请输入" />
                    <van-cell v-if="!item.id" title="必填">
                        <template #value>
                            <van-switch v-model="item.must" size="20px" />
                        </template>
                    </van-cell>
                    <van-field v-if="!item.id" label="可填选项" v-model="item.tempOption" @keyup.enter="addOption(item)" />
                    <van-cell title="" v-if="item.options.length">
                        <template #value>
                            <div v-if="item.options">
                                <van-tag class="m-2 cursor-pointer" v-for="(option, index) in item.options" :key="index"
                                    size="medium" type="primary" @click="removeOption(item, index)">{{ option }}</van-tag>
                            </div>
                        </template>
                    </van-cell>
                    <van-cell title="">
                        <template #value>
                            <van-button class="mx-2" size="small" type="warning" plain
                                @click="moveUp(index)">上移</van-button>
                            <van-button class="mx-2" size="small" type="warning" plain
                                @click="moveDown(index)">下移</van-button>
                            <van-button class="mx-2" size="small" type="danger" plain @click="remove(index)">删除</van-button>
                        </template>
                    </van-cell>
                </van-cell-group>
                <van-button class="w-1/2" type="primary" @click="add()">添加资料项目</van-button>
                <van-button class="w-1/2" type="primary" @click="addDob()">添加生日项目</van-button>
                <van-button class="w-1/2" type="danger" @click="save()">提交资料表单</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import {
    ref,
    onMounted
} from "vue";
import {
    useStore
} from "vuex";
import {
    useRoute,
    useRouter
} from "vue-router";
import {
    Button,
    CellGroup,
    Field,
    Cell,
    Tag,
    Switch,
    Toast
} from "vant";
import ClassDetailsCard from "@/components/ClassDetailsCard.vue";
import myTheme from "@/logics/MyVantTheme.js";
import LoadClass from "@/asyncs/LoadClass.js";
import GetSession from "@/asyncs/GetSession.js";
import SaveForm from "@/asyncs/SaveClassForm.js";

export default {
    components: {
        [Button.name]: Button,
        [CellGroup.name]: CellGroup,
        [Field.name]: Field,
        [Cell.name]: Cell,
        [Tag.name]: Tag,
        [Switch.name]: Switch,
        ClassDetailsCard
    },
    setup() {
        const store = useStore();
        store.commit("showBack");
        store.commit("showMenu");
        store.commit("setPageTitle", "报名需资");
        const route = useRoute();
        const router = useRouter();
        const formItems = ref([]);
        const classDetails = ref(null);
        onMounted(() => {
            GetSession({
                store,
                router,
                route
            }, async function () {
                if (!store.state.classDetails) {
                    classDetails.value = await LoadClass(route.params.classId);
                    if (!classDetails.value) {
                        return;
                    }
                } else {
                    classDetails.value = store.state.classDetails;
                }
                if (classDetails.value.form) {
                    formItems.value = classDetails.value.form;
                    formItems.value.forEach(function (item) {
                        item.tempOption = "";
                    });
                }
            });
        });

        const add = function () {
            formItems.value.push({
                zh: "",
                en: "",
                tempOption: "",
                must: false,
                options: []
            });
        };
        const remove = function (index) {
            formItems.value.splice(index, 1);
        };
        const moveUp = function (index) {
            if (index <= 0) {
                return;
            }
            const upOne = formItems.value[index - 1];
            formItems.value[index - 1] = formItems.value[index];
            formItems.value[index] = upOne;
        };
        const moveDown = function (index) {
            if (index >= formItems.value.length - 1) {
                return;
            }
            const downOne = formItems.value[index + 1];
            formItems.value[index + 1] = formItems.value[index];
            formItems.value[index] = downOne;
        };

        const addOption = function (item) {
            if (!item.tempOption) {
                return;
            }
            item.options.push(item.tempOption);
            item.tempOption = "";
        };
        const removeOption = function (item, index) {
            item.options.splice(index, 1);
            if (!item.options.length) {
                delete item.options;
            }
        }

        const save = async function () {
            const error = await SaveForm(route.params.classId, formItems.value);
            if (error) {
                Toast.fail("提交表单失败: " + error);
            } else {
                Toast.success("提交表单成功");
                classDetails.value.form = formItems.value;
                router.push("/viewClassList");
            }
        };

        const addDob = function () {
            formItems.value.push({
                zh: "",
                en: "",
                id: "dob",
                options: []
            });
        };

        return {
            myTheme,
            classDetails,
            formItems,
            add,
            remove,
            moveUp,
            moveDown,
            addOption,
            removeOption,
            save,
            addDob
        };
    }
}
</script>
